.pricing-area {

  .titleMain{
    font-size: 65px !important;
    color: #cb9339!important
  }
  .subTitleMain{
    font-size: 24px !important;
    color: var(--black-color) !important;
    font-weight: 700;
  }
  .btn {
    color: white !important;
    border: 1px white solid !important;
    border-radius: 8px;
    background: #164178 !important;
  }
  .bar {
    display: none !important;
  }
}
